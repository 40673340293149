<template>
  <section id="landingPageLayout">
    <div class="body">
      <div class="left">
        <div class="image-container">
          <img
            src="https://stageprodpeppespubsa.blob.core.windows.net/staticimages/pizza-cover.webp"
            alt="landing-image"
            loading="eager"
          />
        </div>
      </div>
      <div class="right">
        <div class="title-box">
          <ClientOnly>
            <HeaderLocationSelectorMapPlaceholder />
          </ClientOnly>
          <p class="title">{{ $t("seeMenuAndOrder") }}</p>
          <p class="sub-title">{{ $t("fromPepes") }}</p>
        </div>
        <div class="nav-container">
          <div class="group-button-container">
            <div class="nav-box to-menu" @click="goToMenu">
              <div class="text-box">
                <p class="title-text">{{ $t("seeTakeoutMenu") }}</p>
                <p class="sub-title-text">{{ $t("toorderhome") }}</p>
              </div>
              <div class="nav-icon"><i class="pi pi-arrow-right" /></div>
            </div>
            <div class="nav-box to-reservation" @click="goForReservation">
              <div class="text-box">
                <p class="title-text">{{ $t("reserveatable") }}</p>
                <p class="sub-title-text">{{ $t("atpeppesrestaurant") }}</p>
              </div>
              <div class="nav-icon"><i class="pi pi-arrow-right" /></div>
            </div>
          </div>
          <div class="nav-box download">
            <div class="text-box">
              <p class="title-text">Last ned appen vår!</p>
              <div class="flex justify-content-center align-items-center gap-4 mt-2">
                <NuxtLink to="https://play.google.com/store/apps/details?id=no.peppes.mobile">
                  <img
                    src="~/assets/logo/play-store-logo.png"
                    alt="app-store-logo"
                    height="40px"
                    width="135px"
                  />
                </NuxtLink>
                <NuxtLink to="https://apps.apple.com/us/app/peppes-pizza-2-0/id6499138084">
                  <img src="~/assets/logo/app-store-logo.svg" alt="app-store-logo" />
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div
        class="w-full"
        :style="{
          backgroundImage: 'url(' + `${landingBorder}` + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '1.75rem',
        }"
      ></div>
      <div class="container h-full">
        <div
          class="w-full flex justify-content-center align-items-center h-full flex-column gap-3 md:flex-row md:justify-content-between"
        >
          <div class="phone-box">
            <i class="pi pi-phone" />
            <p>2222 5555</p>
          </div>
          <p class="footer-middle-text">
            <a href="/about/privacy-statement" style="color: inherit; text-decoration: none"
              >Personvernerklæring</a
            >
          </p>
          <div class="copy-right">
            <p>@ Peppes Pizza 2025</p>
            <p>Orgnr: 984 388 659</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useLocalePath } from "#i18n";
import landingBorder from "assets/logo/landing-border.svg";

useHead({
  title: "Peppes",
});
const localePath = useLocalePath();
const goToMenu = async () => {
  await navigateTo(localePath("/menu"));
};
const goForReservation = () => {
  window.location = "https://portal.gastroplanner.no/p/peppespizza";
};
</script>
